import React from "react"
import styled from "@emotion/styled"
import { theme } from "../theme"
import { Container } from "./componentHelpers"
import { Link, useStaticQuery, graphql } from "gatsby"
import SocialLinks from "./socialLinks"
import Img from "gatsby-image"

const StyledHeader = styled.header`
  height: 100px;
  width: 100%;
  background-color: ${theme.colors.white};
  position: relative;
`

const ContentContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

const LinkContainer = styled.div<{ active?: boolean }>`
  cursor: pointer;
  a {
    color: ${theme.colors.grey};
    font-family: Tahoma, Geneva, Verdana, sans-serif;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2px;
    text-decoration: none;
    margin: 10px;
    padding: 0 0 5px;
    &:hover {
      border-bottom: 1px solid ${theme.colors.grey};
    }
  }

  @media (max-width: 767px) {
    display: none;
  }
`

const Header = ({ setFormActive }) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "maxima_logo.png" }) {
        childImageSharp {
          fixed(width: 122) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <StyledHeader>
      <ContentContainer>
        <div>
          <a href="/">
            <Img fixed={data.logo.childImageSharp.fixed} />
          </a>
        </div>
        <LinkContainer>
          <a href="#value-props">About us</a>
          <a
            onClick={() => {
              setFormActive(true)
              setTimeout(() => {
                document.querySelector("#contact").scrollIntoView(true)
              }, 300)
            }}
          >
            Contact
          </a>
        </LinkContainer>
        <SocialLinks color={theme.colors.grey} />
      </ContentContainer>
    </StyledHeader>
  )
}

export default Header
