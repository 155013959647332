import React from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { theme } from "../theme"
import { Container } from "./componentHelpers"
import { CLICK_BELOW } from "../strings"
import SocialLinks from "./socialLinks"
import { lighten } from "polished"

const FooterBackground = styled.div`
  width: 100%;
  background-color: ${theme.colors.lightGrey};
`

const FooterContainer = styled(Container)`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: ${theme.colors.grey};
  font-size: 14px;
  > div {
    display: flex;
    flex-direction: column;
    align-items: left;
    flex-basis: 35%;
    span {
      margin: 5px 0;
    }
    &:nth-of-type(2) {
      flex-basis: 20%;
      align-items: center;
      @media (max-width: 767px) {
        order: 2;
      }
    }
    :last-of-type {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      @media (max-width: 767px) {
        align-items: center;
        margin-bottom: 30px;
        order: 1;
      }
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
    padding-bottom: 60px;
  }
`

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "maxima_stacked.png" }) {
        childImageSharp {
          fixed(width: 122) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <FooterBackground>
      <FooterContainer>
        <div>
          <Img fixed={data.logo.childImageSharp.fixed} />
        </div>
        <div>
          <SocialLinks color={theme.colors.grey} />
          <span>© 2021 Maxima. All rights reserved</span>
        </div>
        <div>
          <span>Phone: 905-828-1421</span>
          <span>Email: support@maximatm.com</span>
          <span>
            Having issues?{" "}
            <a href="http://maximatm.com/esupport">
              http://maximatm.com/esupport
            </a>
          </span>
        </div>
      </FooterContainer>
    </FooterBackground>
  )
}

export default Footer
