import React from "react"
import styled from "@emotion/styled"
import { theme } from "../theme"
import { darken } from "polished"

const CONTAINER_WIDTH = 980

export const Container = styled.div`
  width: ${CONTAINER_WIDTH}px;
  margin: auto;
  box-sizing: border-box;
  @media (max-width: 767px) {
    width: 100%;
    padding: 0 20px;
  }
`

export const Button = styled.button<{ background: string }>`
  border-radius: 100px;
  color: ${theme.colors.white};
  background-color: ${({ background }) => background};
  padding: 15px 60px;
  border: none;
  font-family: Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 18px;
  transition: 0.2s all ease;
  cursor: pointer;
  outline: none;
  :hover {
    background-color: ${({ background }) => darken(0.1, background)};
  }
  :active {
    background-color: ${({ background }) => darken(0.2, background)};
  }
`
