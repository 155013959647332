import React from "react"
import styled from "@emotion/styled"
import { theme } from "../theme"
import { Link } from "gatsby"
import { darken } from "polished"

const SOCIAL_LINKS = [
  {
    icon: "fab fa-facebook-square",
    href: "https://www.facebook.com/maximamasks",
  },
  {
    icon: "fab fa-linkedin",
    href: "https://www.linkedin.com/company/maxima-masks",
  },
  {
    icon: "fab fa-twitter",
    href: "https://twitter.com/maximamasks",
  },
]

const LinkContainer = styled.div`
  flex-grow: 0;
`

const SocialLink = styled.a`
  margin: 0 10px;
`

const Icon = styled.span`
  color: ${props => props.color};
  font-size: 22px;
  transition: 0.2s all ease;
  :hover {
    color: ${props => darken(0.1, props.color)};
  }
`

const SocialLinks = ({ color }) => {
  return (
    <LinkContainer>
      {SOCIAL_LINKS.map(data => {
        return (
          <SocialLink target="_blank" href={data.href}>
            <Icon color={color}>
              <i className={`${data.icon}`} />
            </Icon>
          </SocialLink>
        )
      })}
    </LinkContainer>
  )
}

export default SocialLinks
