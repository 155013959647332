export const theme = {
  colors: {
    white: `#FFFFFF`,
    red: `#ED1D24`,
    purple: `#7F47DD`,
    blue: `#32CECE`,
    darkGrey: `#5b5b5b`,
    grey: `#666666`,
    lightGrey: `#E9E9E9`,
  },
}
