import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "normalize.css"
import "@fortawesome/fontawesome-free/css/all.css"
import "../all.css"

import Header from "./header"
import Footer from "./footer"

const Layout = ({ children, setFormActive }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header setFormActive={setFormActive} />
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
